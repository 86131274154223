import cardHelper from "@/helpers/CardHelper";
import {
  predictAux,
  predictOtherCard,
  predictPreselectedCard,
} from "@/helpers/Prediction";
import Card, { Suit, Value } from "@/models/Card";
import Trick from "@/models/Trick";
import _ from "lodash";
import Vue from "vue";
import settings from "./SettingsManager";

export type GameMode = "game" | "training";
const tricks: Trick[] = [
  {
    index: 1,
    type: "calculator",
    cardsRequired: 4,
    prediction: predictAux,
    isCardAllowed: (card: Card, previousCards: Card[], mode: GameMode) => {
      if (mode == "training") return true;
      return !_.some(
        [
          ...previousCards,
          settings.gameSettings.finalCard,
          ...settings.gameSettings.groupCards,
        ],
        card
      );
    },
  },
  {
    index: 2,
    type: "calculator",
    cardsRequired: 3,
    prediction: predictOtherCard,
    isCardAllowed: (card: Card, previousCards: Card[]) => {
      return (
        _.some(settings.gameSettings.groupCards, card) &&
        !_.some(previousCards, card)
      );
    },
  },
  {
    index: 3,
    type: "calculator",
    cardsRequired: 2,
    prediction: predictPreselectedCard,
    isCardAllowed: (card: Card, previousCards: Card[]) => {
      return !_.some([...previousCards, settings.gameSettings.finalCard], card);
    },
  },
  {
    index: 4,
    type: "climax",
    cardsRequired: 0,
    isCardAllowed: () => false,
    prediction: predictPreselectedCard,
  },
];

class GameManager {
  cards: Card[] = []; // [{suit:"clubs", value:1}, {suit:"clubs", value:2}, {suit:"clubs", value:3}, {suit:"clubs", value:4}, ];
  prediction: Card | Card[] | null = null;
  trickIndex: 0 | 1 | 2 | 3 = 0;
  value: number | null = null;
  suit: Suit | null = null;
  isReversed = false;
  cardStack: Card[] = [];
  isClimax = false;
  public mode: "game" | "training" = "game";

  get trick(): Trick {
    return tricks[this.trickIndex];
  }

  get canAddCard() {
    return this.cards.length < this.trick.cardsRequired;
  }

  get canPredict() {
    return (
      this.cards.length == this.trick.cardsRequired &&
      this.trick.type == "calculator"
    );
  }

  get gameSettings() {
    return settings.gameSettings;
  }

  predict() {
    if (!this.canPredict) {
      return;
    }

    this.prediction = this.trick.prediction(
      this.gameSettings,
      this.cards,
      this.isReversed
    );
  }

  climax() {
    if (this.trick.type != "climax") {
      return;
    }
    this.isClimax = true;
  }
  end() {
    this.trickIndex = 0;
    this.cardStack = [];
    this.cards = [];
    this.isClimax = false;
    this.value = null;
    this.suit = null;
    this.prediction = null;
  }

  resume() {
    if (!this.prediction) {
      return;
    }
    this.trickIndex++;
    this.trickIndex %= tricks.length;
    if (this.trickIndex == 0) {
      this.cardStack = [];
      this.isClimax = false;
    }

    if (this.trick.type == "climax") {
      const endCardStack = [this.prediction as Card, ...this.cards];
      const satckedCards = [...endCardStack, ...this.cardStack];
      const allCardsExceptStackedCards = cardHelper
        .allCards()
        .filter((card) => !_.some(satckedCards, card));
      this.cardStack = [
        ...this.cardStack,
        ..._.sampleSize(allCardsExceptStackedCards, 30),
        ...endCardStack,
      ];
    } else {
      this.cardStack = [
        ..._.reverse(this.cards),
        this.prediction as Card,
        ...this.cardStack,
      ];
    }
    this.cards = [];
    this.prediction = null;
  }

  setValue(value: number) {
    if (!this.canAddCard) {
      return;
    }
    if (this.value == value) {
      this.value = null;
    } else {
      this.value = value;
    }

    this._updateCard();
  }

  setSuit(suit: Suit) {
    if (!this.canAddCard) {
      return;
    }
    if (game.suit == suit) {
      this.suit = null;
    } else {
      this.suit = suit;
    }

    this._updateCard();
  }

  popCard() {
    this.cards = this.cards.filter((_, i) => i != this.cards.length - 1);
  }

  _updateCard() {
    if (this.value != null && this.suit != null) {
      const newCard: Card = {
        suit: this.suit as Suit,
        value: this.value as Value,
      };

      if (
        !this.trick.isCardAllowed(
          newCard,
          [...this.cards, ...this.cardStack],
          this.mode
        )
      ) {
        this.value = null;
        this.suit = null;
        return;
      }

      this.cards = [...this.cards, newCard];

      setTimeout(() => {
        this.value = null;
        this.suit = null;
      }, 10);
    } else {
      if (this.cards.length == 0 && (this.value || this.suit)) {
        this.isReversed = this.suit ? true : false;
      }
    }
  }

  initialize() {
    // empty
  }

  private static _singleton: GameManager;
  static get instance(): GameManager {
    if (!GameManager._singleton) {
      GameManager._singleton = Vue.observable(new GameManager());
      GameManager._singleton.initialize();
    }
    return GameManager._singleton;
  }
}

const game = GameManager.instance;
export default game;
