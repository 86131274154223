
import CalculatorComponent from "@/components/CalculatorComponent.vue";
import CardComponent from "@/components/CardComponent.vue";
import {
  Component,
  Vue,
} from "vue-property-decorator";
import game from "@/managers/GameManager";
import ClimaxComponent from "@/components/ClimaxComponent.vue";
import CardReveal from "@/components/CardReveal.vue";
import GameComponent from "@/components/GameComponent.vue";

@Component({
  components: {
    card: CardComponent,
    game: GameComponent,
    calculator: CalculatorComponent,
    climax: ClimaxComponent,
    CardReveal,
  },
})
export default class GameView extends Vue {
  end() {
    game.end();
    this.$router.replace({ name: "home" });
  }
  get cardStack() {
    return game.cardStack;
  }

  resume() {
    game.resume();
  }
}
