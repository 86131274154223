
import gsap from "gsap/all";
import {
  Component,
  Vue,
} from "vue-property-decorator";

@Component({
  components: {},
})
export default class VideoLoader extends Vue {
  showed = false;
  public mounted(): void {
    this.start();
  }

  public beforeDestroy(): void {
    // empty
  }

  public updated(): void {
    // empty
  }

  ended() {
    this.$emit("ended");
    let tl = gsap.timeline();
    tl.fromTo(
      this.$refs.video as HTMLVideoElement,
      { opacity: 1 },
      { opacity: 0, ease: "ease.out", duration: 0.3 }
    );
    tl.call(() => (this.showed = false));
  }

  start() {
    this.showed = true;
    this.$nextTick(() => {
      let video = this.$refs.video as HTMLVideoElement;
      video.play();
      video.addEventListener("ended", () => this.ended());
    });
  }
}
