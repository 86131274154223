import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import Vue2TouchEvents from "vue2-touch-events";
import { gsap } from "gsap";
import { ExpoScaleEase, RoughEase, SlowMo } from "gsap/EasePack";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { CustomEase } from "gsap/CustomEase";
import { Physics2DPlugin } from "gsap/Physics2DPlugin";
import { PhysicsPropsPlugin } from "gsap/PhysicsPropsPlugin";
import { CustomBounce } from "gsap/CustomBounce";
import { CustomWiggle } from "gsap/CustomWiggle";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import i18n from "./i18n";
import Logo from "@/components/Logo.vue";

Vue.config.productionTip = false;
Vue.use(Vue2TouchEvents);
gsap.registerPlugin(
  CSSRulePlugin,
  CustomEase,
  DrawSVGPlugin,
  Physics2DPlugin,
  PhysicsPropsPlugin,
  CustomBounce,
  CustomWiggle,
  ExpoScaleEase,
  RoughEase,
  SlowMo
);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("Logo", Logo);

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
