import CardTheme from "@/models/themes/CardTheme";

const ThemeCardsCardOracle: CardTheme = {
  faces: {
    clubs: {
      1: require("./AT_GV.svg"),
      2: require("./2T_GV.svg"),
      3: require("./3T_GV.svg"),
      4: require("./4T_GV.svg"),
      5: require("./5T_GV.svg"),
      6: require("./6T_GV.svg"),
      7: require("./7T_GV.svg"),
      8: require("./8T_GV.svg"),
      9: require("./9T_GV.svg"),
      10: require("./10T_GV.svg"),
      11: require("./JT_GV.svg"),
      12: require("./QT_GV.svg"),
      13: require("./KT_GV.svg"),
    },
    diamonds: {
      1: require("./AK_GV.svg"),
      2: require("./2K_GV.svg"),
      3: require("./3K_GV.svg"),
      4: require("./4K_GV.svg"),
      5: require("./5K_GV.svg"),
      6: require("./6K_GV.svg"),
      7: require("./7K_GV.svg"),
      8: require("./8K_GV.svg"),
      9: require("./9K_GV.svg"),
      10: require("./10K_GV.svg"),
      11: require("./JK_GV.svg"),
      12: require("./QK_GV.svg"),
      13: require("./KK_GV.svg"),
    },
    spades: {
      1: require("./AP_GV.svg"),
      2: require("./2P_GV.svg"),
      3: require("./3P_GV.svg"),
      4: require("./4P_GV.svg"),
      5: require("./5P_GV.svg"),
      6: require("./6P_GV.svg"),
      7: require("./7P_GV.svg"),
      8: require("./8P_GV.svg"),
      9: require("./9P_GV.svg"),
      10: require("./10P_GV.svg"),
      11: require("./JP_GV.svg"),
      12: require("./QP_GV.svg"),
      13: require("./KP_GV.svg"),
    },
    hearts: {
      1: require("./AC_GV.svg"),
      2: require("./2C_GV.svg"),
      3: require("./3C_GV.svg"),
      4: require("./4C_GV.svg"),
      5: require("./5C_GV.svg"),
      6: require("./6C_GV.svg"),
      7: require("./7C_GV.svg"),
      8: require("./8C_GV.svg"),
      9: require("./9C_GV.svg"),
      10: require("./10C_GV.svg"),
      11: require("./JC_GV.svg"),
      12: require("./QC_GV.svg"),
      13: require("./KC_GV.svg"),
    },
  },
  icons: {
    clubs: "",
    diamonds: "",
    hearts: "",
    spades: "",
  },
  back: require("./DOS_GV.svg"),
};

export default ThemeCardsCardOracle;
