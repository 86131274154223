
import { Component, Vue } from "vue-property-decorator";
import gsap from "gsap/all";
import userManager from "@/managers/UserManager";

@Component({
  components: {},
  props: {
    onDone: {
      type: Function,
    },
  },
})
export default class CodeComponent extends Vue {
  code = "";
  error = "";

  public contact() {
    window.location.href =
      "mailto:contact@thealgorithm.app?s=" + this.$t("contact.token-help");
  }
  animate() {
    let tl = gsap.timeline();
    tl.from(this.$el, { opacity: 0, scale: 2, duration: 2, ease: "back.out" });
    tl.from(".card", {
      y: 1000,
      duration: 2,
      ease: "power2.inOut",
      delay: "-2",
    });

    tl.from(".card", {
      scale: 0.8,
      duration: 2,
      ease: "back.inOut",
      delay: 0.5,
    });
    tl.to(".card", {
      rotationY: 180,
      duration: 2,
      ease: "power2.inOut",
      delay: "-2",
    });
    tl.from(".next-button", {
      opacity: 0,
      duration: 0.2,
      ease: "power2.inOut",
      delay: 0.5,
    });
  }

  async check() {
    try {
      await userManager.checkCode(this.code);
      this.$props.onDone();
    } catch (error) {
      this.error = error as any;
    }
  }

  public mounted(): void {
    this.animate();
  }

  public beforeDestroy(): void {
    // empty
  }
}
