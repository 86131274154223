import Vue from "vue";
import FirebaseService from "@/services/FirebaseService";
import firebase from "firebase";
import User from "@/models/user/AuthUser";
import i18n from "@/i18n";
import { makeId, NUMERIC } from "@/utils/Random";
import userManager from "../managers/UserManager";

class AuthManager {
  public events = new Vue();
  public user: User | null = null;
  public status = "";
  public auth!: firebase.auth.Auth;

  async getCurrentUser(): Promise<User | null> {
    return new Promise<firebase.User | null>((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
        unsubscribe();
        resolve(firebaseUser);
      }, reject);
    }).then((firebaseUser) => {
      return this.userFromFirebase(firebaseUser);
    });
  }

  async setDisplayName(displayName: string) {
    const firebaseUser = this.auth.currentUser;
    if (firebaseUser) {
      await firebaseUser.updateProfile({ displayName });
      console.log("updated", { displayName });
      await this.auth.currentUser?.reload();
      if (this.user) {
        this.user.displayName = displayName;
      }
    }
  }

  async setEmailAddress(email: string) {
    const firebaseUser = this.auth.currentUser;
    if (firebaseUser) {
      await firebaseUser.updateEmail(email);
      await this.auth.currentUser?.reload();
      if (this.user) {
        this.user.email = email;
      }
    }
  }

  async setPassword(password: string) {
    const firebaseUser = this.auth.currentUser;
    if (firebaseUser) {
      await firebaseUser.updatePassword(password);
      this.auth.signOut();
    }
  }

  public get isAuthenticated() {
    return this.user;
  }

  public async signInWith_EmailAndPassword(email: string, password: string) {
    const credentials = await this.auth.signInWithEmailAndPassword(
      email,
      password
    );
    return credentials;
  }

  public async signUpWith_EmailAndPassword(
    email: string,
    password: string,
    displayName: string
  ) {
    try {
      const credentials = await this.auth.createUserWithEmailAndPassword(
        email,
        password
      );
      const user = credentials.user;
      console.table({
        user,
        currentUser: this.auth.currentUser,
      });
      if (user) {
        await user.updateProfile({ displayName });
        console.log("updated", { displayName });
        await this.auth.currentUser?.reload();
        if (this.user) {
          this.user.displayName = displayName;
        }
        console.log("reloaded");
      }
      console.table({
        user,
        currentUser: this.auth.currentUser,
      });
      return credentials;
    } catch (error) {
      this.events.$emit("sign-in-fail", error);
      throw error;
      console.error(error);
    }
  }

  public async signInWith_Facebook() {
    try {
      const provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope("email");
      provider.addScope("gaming_profile");
      const credentials = await this.auth.signInWithPopup(provider);
      return credentials;
    } catch (error) {
      this.events.$emit("sign-in-fail");
      console.log(error);
    }
  }

  public async signInWith_Google() {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const credentials = await this.auth.signInWithPopup(provider);
      return credentials;
    } catch (error) {
      this.events.$emit("sign-in-fail");
      console.log(error);
    }
  }

  public async signIn_Guest() {
    try {
      const credentials = await this.auth.signInAnonymously();
      const user = credentials.user;
      if (user) {
        const displayName = `${i18n.t("guest")}_${makeId(5, NUMERIC)}`;
        await user.updateProfile({ displayName });
        if (this.user) {
          this.user.displayName = displayName;
        }
      }
      return credentials;
    } catch (error) {
      this.events.$emit("sign-in-fail");
      console.log(error);
    }
  }

  public async forgottenPassword(email: string) {
    this.auth.sendPasswordResetEmail(email);
  }

  public async sendEmailVerification() {
    this.auth.currentUser?.sendEmailVerification();
  }

  public async signOut() {
    console.log("signOut");
    this.auth.signOut();
    this.user = null;
  }

  initialize() {
    this.auth = FirebaseService.auth;
    this.auth.onAuthStateChanged(async (firebaseUser) => {
      this.user = await this.userFromFirebase(firebaseUser);
      userManager.setAuthUser(this.user);
      console.log("onAuthStateChanged", firebaseUser, this.user);
    });
  }

  private async userFromFirebase(firebaseUser: firebase.User | null): Promise<User | null> {
    if (!firebaseUser) {
      return null;
    }

    return {
      uid: firebaseUser?.uid,
      displayName: firebaseUser?.displayName || i18n.t("guest").toString(),
      email: firebaseUser.email,
      photoURL: firebaseUser.photoURL,
    };
  }

  private static _singleton: AuthManager;
  static get instance(): AuthManager {
    if (!AuthManager._singleton) {
      AuthManager._singleton = Vue.observable(new AuthManager());
      AuthManager._singleton.initialize();
    }
    return AuthManager._singleton;
  }
}

const auth = AuthManager.instance;
export default auth;
