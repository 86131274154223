
import game from "@/managers/GameManager";
import settings from "@/managers/SettingsManager";
import {
  Component,
  Vue,
} from "vue-property-decorator";

@Component({
  components: {},
})
export default class CalculatorComponent extends Vue {
  DIFF_X = 105;
  get grid() {
    return settings.grid;
  }

  onKey(el: any) {
    this.$emit("key", el);
    if (el.type == "special") {
      this.$emit("exit");
    }
  }

  // longTouch(el:any){
  //   if(el.type=="special"){
  //     this.$emit("exit")
  //   }
  // }

  selected(el: any) {
    if (el.type == "value" && game.value == el.index) {
      return true;
    } else if (el.type == "suit" && game.suit == el.suit) {
      return true;
    } else if (el.type == "predict" && game.canPredict) {
      return true;
    }
    return false;
  }

  phase() {
    return game.trickIndex;
  }
  get specialLabel() {
    let str = "";
    for (let i = 0; i < game.trickIndex; i++) {
      str += ".";
    }

    return str;
  }
}
