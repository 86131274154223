
import {
  Component,
  Vue,
} from "vue-property-decorator";
import CardPreloader from "./components/CardPreloader.vue";

@Component({
  components: {
    CardPreloader,
  },
})
export default class App extends Vue {
  public mounted(): void {
    // empty
  }

  public beforeDestroy(): void {
    // empty
  }
}
