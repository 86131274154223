
import CalculatorComponent from "@/components/CalculatorComponent.vue";
import CardComponent from "@/components/CardComponent.vue";
import {
  Component,
  Vue,
} from "vue-property-decorator";
import game from "@/managers/GameManager";
import ClimaxComponent from "@/components/ClimaxComponent.vue";
import CardReveal from "@/components/CardReveal.vue";
import VideoBackground from "./VideoBackground.vue";
import VideoLoader from "./VideoLoader.vue";

@Component({
  components: {
    card: CardComponent,
    calculator: CalculatorComponent,
    climax: ClimaxComponent,
    CardReveal,
    VideoBackground,
    VideoLoader,
  },
})
export default class GameComponent extends Vue {
  showExitPopup = false;
  loading = true;
  loadingStep = 0;
  get cards() {
    return game.cards;
  }

  loaded() {
    this.loadingStep = 2;
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

  mounted() {
    setTimeout(() => this.loaded(), 8000);
    setTimeout(() => (this.loadingStep = 1), 2000);
  }

  get prediction() {
    return game.prediction;
  }

  get game() {
    return game;
  }
  exit() {
    if (game.trickIndex == 3) {
      game.climax();
    } else {
      this.exitConfirm();
    }
  }

  exitConfirm() {
    this.showExitPopup = true;
  }
  end() {
    game.end();
    this.$emit("end");
    this.$emit("exit");
  }

  get isClimax() {
    return game.isClimax;
  }

  onCalculatorEvent(el: any) {
    if (el.type == "value") {
      game.setValue(el.index);
    }
    if (el.type == "suit") {
      game.setSuit(el.suit);
    }
    if (el.type == "predict") {
      game.predict();
    }
    // if(el.type == "special"){
    //   game.climax()
    // }
  }

  onCardClick(card: any) {
    game.popCard();
  }

  resume() {
    this.$emit("resume");
  }
}
