
import cardHelper from "@/helpers/CardHelper";
import {
  Component,
  Vue,
} from "vue-property-decorator";
import CardComponent from "./CardComponent.vue";

@Component({
  components: { CardComponent },
})
export default class CardPreloader extends Vue {
  get cards() {
    return cardHelper.allCards();
  }
}
