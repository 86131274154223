import ThemeCardsCardOracle from "@/assets/themes/cards/card_oracle/ThemeCardsCardOracle";
import cardHelper from "@/helpers/CardHelper";
import i18n from "@/i18n";
import Card, { Suit, Value } from "@/models/Card";
import GameSettings from "@/models/GameSettings";
import CardTheme from "@/models/themes/CardTheme";
import _ from "lodash";
import Vue from "vue";
import auth from "../managers/AuthManager";
import userManager from "./UserManager";

type Position = { x: number; y: number; w?: number; h?: number };
type LayoutLine = {
  index: number;
  type: "suit" | "value" | "special" | "predict";
  pos: Position;
};
export type Layout = { id: string; layout: LayoutLine[] };

class SettingsManager {
  readonly SUIT_ORDERS: Suit[][] = [
    ["clubs", "hearts", "spades", "diamonds"],
    ["spades", "hearts", "clubs", "diamonds"],
  ];

  readonly LAYOUTS: Layout[] = [
    {
      id: "top",
      layout: [
        { index: 0, type: "suit", pos: { y: 1, x: 1 } },
        { index: 1, type: "suit", pos: { y: 1, x: 2 } },
        { index: 2, type: "suit", pos: { y: 1, x: 3 } },
        { index: 3, type: "suit", pos: { y: 1, x: 4 } },

        { index: 1, type: "value", pos: { y: 2, x: 1 } },
        { index: 2, type: "value", pos: { y: 2, x: 2 } },
        { index: 3, type: "value", pos: { y: 2, x: 3 } },
        { index: 4, type: "value", pos: { y: 2, x: 4 } },
        { index: 5, type: "value", pos: { y: 3, x: 1 } },
        { index: 6, type: "value", pos: { y: 3, x: 2 } },
        { index: 7, type: "value", pos: { y: 3, x: 3 } },
        { index: 8, type: "value", pos: { y: 3, x: 4 } },
        { index: 9, type: "value", pos: { y: 4, x: 1 } },
        { index: 10, type: "value", pos: { y: 4, x: 2 } },
        { index: 11, type: "value", pos: { y: 4, x: 3 } },
        { index: 12, type: "value", pos: { y: 4, x: 4 } },
        { index: 13, type: "value", pos: { y: 5, x: 1 } },
        { index: 0, type: "special", pos: { y: 5, x: 2 } },
        { index: 0, type: "predict", pos: { y: 5, x: 3, w: 2 } },
      ],
    },

    {
      id: "left",
      layout: [
        { index: 0, type: "suit", pos: { y: 1, x: 1 } },
        { index: 1, type: "suit", pos: { y: 2, x: 1 } },
        { index: 2, type: "suit", pos: { y: 3, x: 1 } },
        { index: 3, type: "suit", pos: { y: 4, x: 1 } },

        { index: 1, type: "value", pos: { y: 1, x: 2 } },
        { index: 2, type: "value", pos: { y: 1, x: 3 } },
        { index: 3, type: "value", pos: { y: 1, x: 4 } },
        { index: 4, type: "value", pos: { y: 2, x: 2 } },
        { index: 5, type: "value", pos: { y: 2, x: 3 } },
        { index: 6, type: "value", pos: { y: 2, x: 4 } },
        { index: 7, type: "value", pos: { y: 3, x: 2 } },
        { index: 8, type: "value", pos: { y: 3, x: 3 } },
        { index: 9, type: "value", pos: { y: 3, x: 4 } },
        { index: 10, type: "value", pos: { y: 4, x: 2 } },
        { index: 11, type: "value", pos: { y: 4, x: 3 } },
        { index: 12, type: "value", pos: { y: 4, x: 4 } },
        { index: 13, type: "value", pos: { y: 5, x: 2 } },

        { index: 0, type: "special", pos: { y: 5, x: 1 } },
        { index: 0, type: "predict", pos: { y: 5, x: 3, w: 2 } },
      ],
    },
  ];

  _gameSettings: GameSettings = {
    suitsOrder: this.SUIT_ORDERS[0],
    finalCard: { value: 13, suit: "spades" },
    groupCards: [
      { value: 6, suit: "hearts" },
      { value: 8, suit: "clubs" },
      { value: 12, suit: "spades" },
      { value: 13, suit: "diamonds" },
    ],
    layoutId: this.LAYOUTS[1].id,
    preditionAutoNextDelay: 0,
    themeIndex: 1,
  };
  _tempSettings: Partial<GameSettings> = {};

  get gameSettings() {
    return {
      ...this._gameSettings,
      ...this._tempSettings,
    };
  }

  set gameSettings(value: GameSettings) {
    this._gameSettings = value;
    this.save();
  }

  async save() {
    if (!auth.user) {
      return;
    }
    const ref = await userManager.userRef.collection("settings").doc("game");
    await ref.set(this._gameSettings);
  }
  async saveLang() {
    if (!auth.user) {
      return;
    }
    const ref = await userManager.userRef.collection("settings").doc("general");
    await ref.set({ lang: this.lang });
  }

  async restore() {
    const gameDoc = await userManager.userRef
      .collection("settings")
      .doc("game")
      .get();
    if (gameDoc.exists) {
      this._gameSettings = gameDoc.data() as GameSettings;
    }

    const generalDoc = await userManager.userRef
      .collection("settings")
      .doc("general")
      .get();
    if (generalDoc.exists) {
      this.setLang((generalDoc.data() as { lang?: string }).lang || "en");
    }
  }

  lang: string | null = null;

  setLang(lang: string) {
    this.lang = lang;
    i18n.locale = lang;
    this.saveLang();
  }

  get theme() {
    return null;
  }

  get layout() {
    return (
      this.LAYOUTS.find((layout) => layout.id == this.gameSettings.layoutId) ||
      this.LAYOUTS[0]
    ).layout;
  }

  get grid() {
    return this.layout.map((line) => {
      let ref: Value | Suit | { label: string } | Record<string, never> = {};

      if (line.type == "value") {
        ref = cardHelper.valueRef(line.index as Value);
      } else if (line.type == "suit") {
        const suit = this.gameSettings.suitsOrder[line.index];
        ref = cardHelper.suitRef(suit);
      } else if (line.type == "predict") {
        ref = { label: "PREDICT" };
      } else if (line.type == "special") {
        ref = { label: "..." };
      }
      return {
        ...line,
        ...ref,
      };
    });
  }

  get cardTheme(): CardTheme {
    return ThemeCardsCardOracle;
  }

  setCard(index: number, card: Card) {
    if (index == 4) {
      this.gameSettings = { ...this.gameSettings, finalCard: card };
    } else {
      this.gameSettings = {
        ...this.gameSettings,
        groupCards: _.set(this.gameSettings.groupCards, index, card),
      };
    }
  }

  setSuit(suitOrder: Suit[]) {
    this.gameSettings = { ...this.gameSettings, suitsOrder: suitOrder };
  }

  setLayout(layout: Layout) {
    this.gameSettings = { ...this.gameSettings, layoutId: layout.id };
  }

  setPreditionAutoNextDelay(duration: number) {
    this.gameSettings = {
      ...this.gameSettings,
      preditionAutoNextDelay: duration,
    };
  }

  apply(settings: Partial<GameSettings>) {
    this._tempSettings = settings;
  }

  themeDefault = [];
  initialize() {
    // empty
  }

  private static _singleton: SettingsManager;
  static get instance(): SettingsManager {
    if (!SettingsManager._singleton) {
      SettingsManager._singleton = Vue.observable(new SettingsManager());
      SettingsManager._singleton.initialize();
    }
    return SettingsManager._singleton;
  }
}

const settings = SettingsManager.instance;
export default settings;
