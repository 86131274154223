
import { Component, Vue } from "vue-property-decorator";
import invitation from "@/managers/InvitationManager";
import CardComponent from "@/components/CardComponent.vue";
import game from "@/managers/GameManager";
import gsap from "gsap/all";
import _ from "lodash";
import Logo from "./Logo.vue";

@Component({
  components: {
    card: CardComponent,
    logo: Logo,
  },
})
export default class ClimaxComponent extends Vue {
  count = 0;
  wtf = false;
  done = false;
  finish = false;
  styles: any[] = [];
  lastEls = [
    { yPercent: 0, rotate: -15 },
    { yPercent: -5, rotate: 10 },
    { yPercent: -12, rotate: -2 },
  ];

  tap() {
    if (this.count < 8) {
      this.sendCard(this.count);
      this.count++;
    } else if (!this.wtf) {
      this.wtf = true;
      const _int = setInterval(() => {
        const lasts =
          this.count - (this.climaxCards.length - this.lastEls.length);
        if (lasts < 0) {
          this.sendCard(this.count);
          this.count++;
        } else if (lasts <= this.lastEls.length) {
          this.sendCard(this.count, this.lastEls[lasts]);
          this.count++;
        } else {
          clearInterval(_int);

          setTimeout(() => {
            this.done = !this.done;
          }, 2000);
        }
      }, 30);
    }
  }

  async endCmd() {
    this.done = false;
    await this.reset();
    gsap.to(".end", { opacity: 1, duration: 2, scale: 1, delay: 1 });
    gsap.to(".exit", { opacity: 1, duration: 0.4, scale: 1, delay: 4 });

    // setTimeout(()=>this.$emit("done"), 10000);
  }

  mounted() {
    gsap.from(this.$el, { opacity: 0, duration: 1, yPercent: 100 });
    gsap.set(".end", { opacity: 0, scale: 0.6 });
    gsap.set(".exit", { opacity: 0, scale: 1 });
    this.styles = this.climaxCards.map((_, n) => {
      return {
        top: "calc(45vh + " + Math.floor(0.5 * n) * 2 + "px)",
        zIndex: n + 1 + (n === this.climaxCards.length - 1 ? 10 : 0),
      };
    });

    invitation
      .forceInvitationInvalidation()
      .then(() => console.log("Invitation invalidated"))
      .catch(() => console.log("Could not invalidate invitation"));
  }

  reset() {
    return new Promise<void>((resolve) => {
      let count = 0;
      const _int = setInterval(() => {
        if (count < this.styles.length) {
          this.resetCard(count);
          count++;
        } else {
          clearInterval(_int);
          resolve();
        }
      });
    });
  }

  resetCard(n: number) {
    this.styles[n] = {
      top: "calc(-80vh + " + 0.5 * n + "px)",
      zIndex: n + 1,
    };
    this.styles = [...this.styles];
  }
  doneCmd() {
    if (this.$route.name == "invite") {
      window.location.href = "https://google.com";
    } else {
      this.$emit("done");
    }
  }

  sendCard(n: number, force: any = {}) {
    const newVal = {
      duration: 0.8,
      yPercent: _.random(0, -20) - 6,
      bottom: "50%",
      rotate: _.random(-15, 15),
      ...force,
    };
    this.styles[this.styles.length - n - 1] = {
      top: newVal.yPercent + "%",
      zIndex: n + 100,
      transform: "rotate(" + newVal.rotate + "deg)",
    };
    this.styles = [...this.styles];
  }

  get climaxCards() {
    return _.reverse(game.cardStack);
    // return cardHelper.allCards()
  }

  get positionnedCard() {
    return game.cardStack;
  }
}
