
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class InstallGuide extends Vue {
  showIOSInstallInstruction = false;

  public mounted(): void {
    this.showIOSInstallInstruction = this.isIOS();
  }

  public beforeDestroy(): void {
    // empty
  }

  private isIOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.userAgent) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }
}
