
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class VideoBackground extends Vue {
  public updated(): void {
    let video = this.$refs.video as HTMLVideoElement;
    video.play();
  }
}
