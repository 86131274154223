import { coding, routine } from "@/conf/instructions";
import { credits } from "../conf/instructions";

export const messages = {
  title: "The Algorithm",
  auth: {
    title: "Connexion",
    "sign-out": "Déconnexion",
    email: "Adresse email",
    emailConfirm: "Confirmation de l'adresse email",
    "token-next-step":
      "Votre code d'activation vous sera demandé à l'étape suivante",
    password: "Mot de passe",
    passwordConfirm: "Confirmation de mot de passe",
    displayName: "Nom et prénom",
    "log-in": "Connexion",
    "sign-in": "Connexion",
    "sign-up": "Inscription",
    noAccountYet: "Pas encore de compte? Allez sur la ",
    signUpPage: "page de création de compte",
    alreadyHaveAnAccount: "Vous avez déjà un compte ? Allez sur la ",
    signInPage: "page de connexion",
    forgottenPassword: "Mot de passe oublié ?",
    separator: "Ou",
    "tier-sign-in": {
      google: "Se connecter avec Google",
      facebook: "Se connecter avec Facebook",
    },
    "tier-sign-up": {
      google: "S'inscrire avec Google",
      facebook: "S'inscrire avec Facebook",
    },
    create: "Créer un compte",
    "switch-to-sign-in": "J'ai déja un compte, me connecter",
    "switch-to-sign-up": "Nouvel utilisateur? Créer un compte.",
  },

  learning: {
    routine: routine.fr,
    coding: coding.fr,
  },

  settings: {
    settings: "Paramètres",
    account: "Compte",
    "account-sub": "Gérer votre profil",
    general: "général",
    "general-sub": "Paramètres de l'application",
    learn: "Apprentissage",
    "learn-sub": "Apprendre le tour",
    configuration: "Configuration",
    "configuration-sub": "Personaliser le tour",
    about: "À propos",
    "about-sub": "The Algorithm",

    language: "Langue",

    routine: "Routine",
    "routine-sub": "Guide étape par étape",

    coding: "Codage",
    "coding-sub": "Prédire la bonne carte",

    videos: "Videos",
    "videos-sub": "Vidéos étape par étape",

    training: "Entrainement",
    "training-sub": "S'entrainer au codage",

    delay: "Reprise auto",
    "delay-explaination":
      "Si activé, le tour reprend automatiquement X secondes après avoir révélé la carte",

    stack: "Pile de départ",
    "suits-order": "Ordre des couleurs",
    layout: "Disposition",
    top: "Haut",
    left: "Gauche",

    version: "version",
    contact: "contact",

    "third-step": "Troisième phase",
    "second-step": "Deuxième phase",

    save: "valider",
    cancel: "annuler",
    credits: "Credits",

    "full-credits": credits.fr,
  },

  code: {
    "your-code": "Saisissez votre code d'activation ",
    check: "Valider",
    "no-card": "Besoin d'aide?",
    "find-a-resseler": "Contactez-nous",
  },
  guide: {
    "install-ios": `Appuyez sur le bouton <i class="icon-export"></i>, et selectionnez "Ajouter à l'écran d'accueil" <i class="icon-shareios"></i>.`,
    "install-android": `Appuyez sur le bouton <i class="icon-more"></i>, et selectionnez "Ajouter à l'écran d'accueil" ou "Installer l'App".`,
  },

  contact: {
    "general-help": "Aide Générale",
    "token-help": "Aide sur l'accès à l'app'",
    crediting: "Credits",
  },
};
