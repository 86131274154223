import Vue from "vue";
import firebaseService from "@/services/FirebaseService";
import auth from "./AuthManager";
import settings from "./SettingsManager";
import GameSettings from "@/models/GameSettings";
import { makeId, NUMERIC } from "@/utils/Random";

export interface Invitation {
  token: string;
  gameSettings: GameSettings;
  url: string;
  consumer: string | null;
  usedAt: number | null;
}

class InvitationManager {
  private guestInvitationUserId: string | null = null;
  private guestInvitationToken: string | null = null;

  public async getCurrentInvitation() {
    if (!auth.user) {
      throw "cannot restore invitation";
    }

    const doc = await this.invitationRef(auth.user.uid).get();
    const invitation = doc.data() as Invitation;

    if (!invitation || this.isInvitationExpired(invitation)) {
      return this.createInvitation();
    }

    return invitation;
  }

  public async createInvitation() {
    if (!auth.user) {
      throw "cannot create invitation";
    }
    const token = makeId(6, NUMERIC);
    const baseUrl = "https://thealgorithm.app";
    const url =
      baseUrl +
      "/invite?userId=" +
      encodeURIComponent(auth.user?.uid || "") +
      "&token=" +
      token;
    const invitation: Invitation = {
      token: token,
      gameSettings: settings.gameSettings,
      consumer: null,
      usedAt: null,
      url: url,
    };
    await this.invitationRef(auth.user.uid).set(invitation);

    return invitation;
  }

  public async consumeInvitation(userId: string, token: string) {
    console.log("consumeInvitation", userId, token);
    const doc = await this.invitationRef(userId).get();

    if (!doc.exists) {
      throw "no-invitation";
    }

    const invitation = doc.data() as Invitation;

    if (invitation.token != token) {
      throw "invitation-not-active";
    }

    if (this.isInvitationExpired(invitation)) {
      throw "invitation-expired";
    }

    if (invitation.usedAt === null) {
      await doc.ref.update({
        usedAt: Date.now(),
      });
    }
    settings.apply(invitation.gameSettings);
    this.guestInvitationUserId = userId;
    this.guestInvitationToken = token;

    return invitation;
  }

  public async forceInvitationInvalidation() {
    if (
      this.guestInvitationUserId === null ||
      this.guestInvitationToken === null
    ) {
      return false;
    }

    try {
      const doc = await this.invitationRef(this.guestInvitationUserId).get();

      if (!doc.exists) {
        return false;
      }

      const invitation = doc.data() as Invitation;

      if (invitation.token !== this.guestInvitationToken) {
        return false;
      }

      await doc.ref.update({
        usedAt: 0,
      });

      this.guestInvitationToken = null;
      this.guestInvitationUserId = null;

      return true;
    } catch {
      return false;
    }
  }

  invitationRef(userId: string) {
    return firebaseService.firestore.collection("invitations").doc(userId);
  }

  initialize() {
    // empty
  }

  private isInvitationExpired(invitation: Invitation): boolean {
    // 15 min validity
    return (
      invitation.usedAt !== null &&
      Date.now() > invitation.usedAt + 15 * 60 * 1000
    );
  }

  private static _singleton: InvitationManager;
  static get instance(): InvitationManager {
    if (!InvitationManager._singleton) {
      InvitationManager._singleton = Vue.observable(new InvitationManager());
      InvitationManager._singleton.initialize();
    }
    return InvitationManager._singleton;
  }
}

const invitations = InvitationManager.instance;
export default invitations;
