
import {
  Component,
  Vue,
} from "vue-property-decorator";

@Component({
  components: {},
})
export default class Logo extends Vue {
  public mounted(): void {}

  public beforeDestroy(): void {}
}
