import Card, { Suit, Value } from "@/models/Card";
import _ from "lodash";

const VALUES: Array<{
  index: Value;
  label: string;
  labelFull: string;
  labelShort: string;
}> = [
  { index: 1, label: "A", labelFull: "ACE", labelShort: "A" },
  { index: 2, label: "2", labelFull: "2", labelShort: "2" },
  { index: 3, label: "3", labelFull: "3", labelShort: "3" },
  { index: 4, label: "4", labelFull: "4", labelShort: "4" },
  { index: 5, label: "5", labelFull: "5", labelShort: "5" },
  { index: 6, label: "6", labelFull: "6", labelShort: "6" },
  { index: 7, label: "7", labelFull: "7", labelShort: "7" },
  { index: 8, label: "8", labelFull: "8", labelShort: "8" },
  { index: 9, label: "9", labelFull: "9", labelShort: "9" },
  { index: 10, label: "10", labelFull: "10", labelShort: "10" },
  { index: 11, label: "J", labelFull: "JACK", labelShort: "J" },
  { index: 12, label: "Q", labelFull: "QUEEN", labelShort: "Q" },
  { index: 13, label: "K", labelFull: "KING", labelShort: "K" },
];

type suitInfo = {
  index: Value;
  suit: Suit;
  label: string;
  labelFull: string;
  labelShortEn: string;
  labelShortFr: string;
  color: string;
};

const SUITS: { [key in Suit]: suitInfo } = {
  spades: {
    index: 1,
    suit: "spades",
    label: "♠︎",
    labelFull: "SPADES",
    labelShortEn: "S",
    labelShortFr: "P",
    color: "black",
  },
  hearts: {
    index: 2,
    suit: "hearts",
    label: "♥︎",
    labelFull: "HEARTS",
    labelShortEn: "H",
    labelShortFr: "C",
    color: "red",
  },
  clubs: {
    index: 3,
    suit: "clubs",
    label: "♣︎",
    labelFull: "CLUBS",
    labelShortEn: "C",
    labelShortFr: "T",
    color: "black",
  },
  diamonds: {
    index: 4,
    suit: "diamonds",
    label: "♦︎",
    labelFull: "DIAMONDS",
    labelShortEn: "D",
    labelShortFr: "K",
    color: "red",
  },
};

class CardHelper {
  public orderCard(cards: Card[], suitsOrder: Suit[]) {
    return _.sortBy(cards, (card) => this.cardValue(card, suitsOrder));
  }

  public equals(card1: Card, card2: Card) {
    return card1.value == card2.value && card1.suit == card2.suit;
  }

  private cardValue(card: Card, suitsOrder: Suit[]) {
    return card.value * 100 + suitsOrder.indexOf(card.suit);
  }

  suitIcon(suit: Suit) {
    return SUITS[suit].label;
  }

  suitRef(suit: Suit) {
    return SUITS[suit];
  }

  valueRef(value: Value) {
    return VALUES[value - 1];
  }

  allCards(): Card[] {
    const cards = _.flatten(
      this.allSuits().map((suit) => {
        return this.allValues().map((value) => {
          return { suit, value } as Card;
        });
      })
    );

    return cards;
  }

  allValues(): Value[] {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
  }

  allSuits(): Suit[] {
    return ["spades", "hearts", "clubs", "diamonds"];
  }

  initialize() {
    // empty
  }

  private static _singleton: CardHelper;
  static get instance(): CardHelper {
    if (!CardHelper._singleton) {
      CardHelper._singleton = new CardHelper();
      CardHelper._singleton.initialize();
    }
    return CardHelper._singleton;
  }
}

const cardHelper = CardHelper.instance;
export default cardHelper;
