export const NUMERIC = "0123456789";
export const ALPHA_NUMERIC = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
export const READABLE_ALPHA_NUMERIC = "ACEFGHJMNPQRTWXYZ34679";

export function makeId(length: number, characters = ALPHA_NUMERIC) {
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
