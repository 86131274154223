import firebase from "firebase";

// Required for side-effects
require("firebase/firestore");
require("firebase/database");
require("firebase/auth");

class FirebaseService {
  public get firestore() {
    return firebase.firestore();
  }

  public get db() {
    return firebase.database();
  }

  public getDatabase(appName: string) {
    const app = firebase.app(appName);
    return firebase.database(app);
  }

  public get auth() {
    return firebase.auth();
  }

  public get functions() {
    return firebase.functions();
  }
  public get useruid() {
    return firebase.auth().currentUser?.uid;
  }

  private localDev() {
    const db = firebase.firestore();

    console.log(process.env.NODE_ENV, process.env.VUE_APP_FIREBASE_API);

    if (
      process.env.NODE_ENV === "development" &&
      process.env.VUE_APP_FIREBASE_API === "emulator"
    ) {
      console.log(process.env.NODE_ENV, " - Setting emulator up");

      const auth = firebase.auth();
      auth.useEmulator("http://localhost:9099");

      db.useEmulator("localhost", 8080);

      const functions = firebase.functions();
      functions.useEmulator("localhost", 5001);

      const storage = firebase.storage();
      storage.useEmulator("localhost", 9199);
    }

    db.enablePersistence();
  }

  initialize() {
    const config = {
      apiKey: "AIzaSyCGLy1loPyIyphT9_a367XrikYD6oh_q2I",
      authDomain: "the-algorithm-37cc6.firebaseapp.com",
      databaseURL:
        "https://the-algorithm-37cc6-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "the-algorithm-37cc6",
      storageBucket: "the-algorithm-37cc6.appspot.com",
      messagingSenderId: "212651020936",
      appId: "1:212651020936:web:c46839fc168b3949cb053d",
      measurementId: "G-3NWLK0GDRG",
    };

    firebase.initializeApp(config);

    this.localDev();
  }

  private static _singleton: FirebaseService;
  static get instance(): FirebaseService {
    if (!FirebaseService._singleton) {
      FirebaseService._singleton = new FirebaseService();
      FirebaseService._singleton.initialize();
    }
    return FirebaseService._singleton;
  }
}

const firebaseService = FirebaseService.instance;
export default firebaseService;
