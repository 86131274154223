export const routine = {
  fr: `
  <div class="item">
    <span>INTRODUCTION</span>
  </div>
  <div class="item item-text">
    Pour apprendre rapidement à utiliser The Algorithm, je vous invite à stopper la lecture de ces explications et de commencer par apprendre à coder une carte grace à 4 autres dans l'onglet Codage.
    <br />
    Les explications sont volontairement succinctes. Elles donnent les principes et les idées directrices. Pour les détails (manipulation des cartes, positions en main et sur table, etc.), se référer à la vidéo montrant le déroulé de la routine.
  </div>

  <div class="item">
    <span>DÉROULÉ GÉNERAL</span>
  </div>
  <div class="item item-text">
    Cette routine se déroule en 4 phases:
    <ul>
      <li>1ère phase : le spectateur mélange le jeu, donne 5 cartes; 4 cartes prédisent la 5ème.</li>
      <li>2ème phase : le spectateur donne 4 cartes et les mélange entre elles; 3 cartes prédisent la 4ème.</li>
      <li>3ème phase : le spectateur coupe une partie du jeu face en l'air, sélectionne 2 cartes; elles prédisent la prochaine carte face en bas.</li>
      <li>4ème phase : on constate que The Algorithm connait l'ordre de tout le jeu !</l>
    </ul>
    Chaque phase utilise une méthode différente et permet de prendre secrètement jusqu'à 3 temps d'avance pour les phases suivantes. C'est tout cela qui rend cette routine incompréhensible et magique.
  </div>

  <div class="item">
    <span>PRÉPARATION</span>
  </div>
  <div class="item item-text">
    Il vous faut un jeu de carte de 52 cartes et son étui, dont vous avez déchiré les languettes latérales.

    <img src="/img/instructions/routine_1.png" />

    D'origine The Algorithm a en mémoire un arrangement de 5 cartes :
    <ul>
      <li>dame de pique,</li>
      <li>roi de carreau,</li>
      <li>8 de trèfle,</li>
      <li>6 de coeur</li>
      <li>et roi de pique.</li>
    </ul>
    Vous pouvez les modifier dans les paramètres.
    <br /><br />

    Cacher ces 5 cartes dans l'étui, dissimulées avec le flapet
    <img src="/img/instructions/routine_3.png" />
    et mettre le reste des cartes dans l'étui.
    <img src="/img/instructions/routine_4.png" />

    Vous êtes prêt.
  </div>

  <div class="item">
    <span>ROUTINE</span>
  </div>
  <div class="item item-text">
    <b>1ère phase</b>
    <br />
    Le spectateur mélange le jeu et donne 5 cartes sur la table, ranger le reste du jeu dans l'étui en refermant le flapet, ce qui permet d'ajouter secrètement le montage des 5 cartes sur le jeu...pour plus tard.
    <br />
    Revenons au 5 cartes distribuées. En les égalisant, on flash la dernière carte qui est mise de côté pour être prédite. C'est ensuite l'ordre dans lequel on saisit les 4 cartes restantes qui permet à The Algorithm de savoir quelle est cette 5ème carte.
    C'est un code qui transmet secrètement la carte à révéler à The Algorithm (pour cette phase refférez vous au chapitre codage).
    <br />
    Au fur et à mesure que le spectateur saisit les cartes, on les pose en pile face en l'air l'une sur l'autre. Une fois révélée la carte "prédite" sert de pelle pour les ramasser et dans le même geste les reposer de côté face en bas. The Algorithm a enregistrées ces cartes en mémoire, elles serviront pour la phase finale. Cette procédure de collecte de se répète à chaque phase de manière identique.

    <br/><br/>
    <b>2ème phase</b>
    <br />
    Le jeu est ressorti de l'étui avec maintenant sur le dessus les 5 cartes du montage.
    Le spectateur donne 4 cartes sur la table.
    Il reste encore la 5ème carte du montage sur le paquet pour plus tard.
    On dépose nonchalamment le paquet sur les 5 cartes de la phase précédente. Sous le jeu se trouvent donc 5 cartes mémorisées par The Algorithm.
    <br />
    Le spectateur choisit la carte des 4 qu'il aimerai faire prédire, puis saisit les 3 cartes restantes. Par élimination The Algorithm révèle la 4ème.
    Les 4 cartes sont regroupées et posées face en bas en respectant la procédure de collecte. Puis, le jeu est posé dessus; elles viennent donc s'ajouter aux 5 cartes précédemment placées, portant à 9 cartes la mémoire de The Algorithm.

    <br/><br/>
    <b>3ème phase</b>
    <br />
    En utilisant un forçage nommé cut deeper force, on force la 5ème et dernière carte du montage, qui est sur le dessus du paquet :
    Couper un très petit paquet. Le retourner sur le jeu. Demander au spectateur de couper à son tour un plus gros paquet et de retourner sur le jeu. Étaler le jeu en ruban. La carte forcée est la première carte face en bas.
    <br />
    Le spectateur sort 2 cartes du demi-ruban face en l'air (qui résulte de la mécanique du cut deeper force).
    Quelles que soient les 2 cartes sélectionnées par le spectateur, The Algorithm révèle la carte forcée.
    Une fois la carte révélée, on regroupe le paquet de 3 cartes (procédure de collecte). Il est cette fois-ci déposé face en l'air sur le paquet, qui est immédiatement retourné.

    <br /><br />
    <b>4ème phase</b>
    <br />
    Revenu à l'interface de saisie, on demande au spectateur d'appuyer sur la touche "exit". Cela fait apparaître un jeu virtuel... qui montre sur le dessus la même carte que celle qu'on voit sur le paquet réel!
    <br />
    En faisant défiler sur les 2 jeux (virtuel et réel) les cartes une par une, on donne l'impression aux spectateurs que The Algorithm connait l'intégralité du jeu! Il s'agit en fait d'un bluff. Il n'en connait que les 9 premières (face en l'air) et les 3 dernières (face en bas).
    On révèle une par une les 9 premières cartes, dans un rythme croissant. Puis l'application lance le défilement automatique de la fin du paquet.
    <br />
    Simultanément, on coupe le jeu à la séparation entre les cartes face en l'air et les cartes face en bas, puis on étale la fin des cartes face en l'air sur les cartes révélées. Dans la continuité, on prends le demi paquet face en bas, on le retourne et on l'étale en ruban à la suite.
    <br />
    Avec un bon timing, l'animation sur l'iPhone et la manipulation du jeu terminent en même temps, avec un visuel qui correspond!
    <br />
    <br />
    <b>Variante de la première phase par Michael Weber</b>
    <br />
    Si coder les cartes vous fait peur, voici une autre façon de faire qui est entièrement automatique :<br />
    Laissez le spectateur mélanger les cartes puis récupérez-les. Retirez le 2-6 de carreau mais ne leur montrez pas tout de suite.<br />
    Mettez-les dans l'ordre : 2, 3, 4, 5, 6 face à vous puis retournez les cartes dans vos mains. Mettez la carte du bas de côté (6 de carreau).<br />
    Expliquez que l'app peut servir de "calculatrice de Poker". Donnez-leur successivement les 2, 3, 4, et 5 dans l'ordre pour les entrer dans The Algorithm.<br />
    The Algorithm prédit que la meilleure 5ème carte pour cette main est le 6 de carreau.
    Retournez la carte mise de côté pour révéler que la prédiction de The Algorithm est correcte : il s'agit bien du 6 de carreau.
  </div>
`,

  en: `
<div class="item">
  <span>INTRODUCTION</span>
</div>
<div class="item item-text">
  The explanations are deliberately brief.
  They give the principles and guiding ideas. For details (card handling, hand and table positions, etc.), refer to the video showing the routine.
</div>

<div class="item">
  <span>OVERVIEW</span>
</div>
<div class="item item-text">
  This routine is performed in 4 phases:
  <ul>
    <li>1st phase: the spectator shuffles the deck and deals 5 random cards: 4 cards are used to predict the 5th.</li>
    <li>2nd phase: the spectator is given 4 cards and shuffles them together: 3 cards are used to predict the 4th.</li>
    <li>3rd phase: the spectator cuts a part of the deck face up and selects any 2 cards: they predict the next face down card.</li>
    <li>4th phase: The Algorithm reveals the order of the whole deck!</l>
  </ul>
  Each phase uses a different method and secretly sets you up to 3 steps ahead for the following phases. All this makes this routine incomprehensible and magical.
</div>

<div class="item">
  <span>SETUP</span>
</div>
<div class="item item-text">
  You will need a 52-card deck and its case, whose side tabs have been torn off.
  <img src="/img/instructions/routine_1.png" />

  Originally The Algorithm has an arrangement of 5 cards stored in its memory:
  <ul>
    <li>Queen of Spades,</li>
    <li>King of Diamonds,</li>
    <li>8 of Clubs,</li>
    <li>6 of Hearts,</li>
    <li>and King of Spades.</li>
  </ul>
  You can change these in the settings.
  <br /><br />

  Hide these 5 cards in the case under the flap
  <img src="/img/instructions/routine_3.png" />
    and put the rest of the cards in the case
   <img src="/img/instructions/routine_4.png" />

   You are ready
</div>

<div class="item">
  <span>ROUTINE</span>
</div>
<div class="item item-text">
  <b>1st phase</b>
  <br />
  The spectator shuffles the deck and puts 5 cards on the table. The rest of the deck is placed back in the case and by closing the flap, the 5 memorised cards are added to the top of the deck...for later.
  <br />
  Let's go back to the 5 dealt cards. By squaring them, we glimpse the bottom card which is then set aside face down to be predicted. The order in which the 4 remaining cards are entered allows The Algorithm to know the 5th card is. It is a code that secretly enables the card to be calculated by The Algorithm.
  <br />
  Once revealed, the "predicted" card is used as a shovel to scoop them up and in the same gesture put them down face down on the table.
  The Algorithm remembers these cards in its memory, they will be used for the final phase. This procedure of replacement is repeated at each phase in an identical way.

  <br/><br/>
  <b>2nd phase</b>
  <br />
  The deck of cards is removed from the case, now with the hidden 5 cards added to the top. The spectator deals 4 cards on the table. (The 5th card of the added stack remains on top of the deck for later.)The deck is placed nonchalantly on top of the 5 cards left from the previous phase. On the bottom of the deck there are now the 5 cards stored by The Algorithm.
  <br />
  The spectator chooses any one of the 4 cards that he would like to predict, then enters the remaining 3 into the The Algorithm. By elimination, The Algorithm reveals the 4th card.The 4 cards are collected together and placed face down as per the replacement procedure. Then, the deck is placed on top, adding them to the 5 cards previously placed on the bottom, bringing the memory of The Algorithm to 9 cards.

  <br/><br/>
  <b>3rd phase</b>
  <br />
  Using a force called the cut deeper force, we force the 5th and last card of the stack, which is on the top of the deck: cut a very small portion of the deck, turn it over and replace it face up on the face down deck. Ask the spectator to cut a larger portion and to also turn it over on and place it on top of the deck. Ribbon spread out the cards on the table. The forced card will be the first card face down in the spread.
  <br />
  The spectator takes 2 cards out of the face up cards spread on the table (which results from the mechanics of cut deeper force.) Whatever the 2 cards selected by the spectator, The Algorithm reveals the forced card.Once the card is revealed, the pack of 3 cards is collected (collection procedure). This time it is placed face up on the deck, which is immediately turned over.

  <br /><br />
  <b>4th phase</b>
  <br />
  On the input interface, the viewer is asked to press the "exit" key. This brings up a virtual deck of cards face up... the top card shown matches the one on the real deck!
  <br />
  By scrolling through the 2 decks (virtual and real) at the same time card by card, the spectators get the impression that The Algorithm knows the whole deck! This is actually a bluff. It only knows the first 9 cards (face up) and the last 3 (face down). The first 9 cards are revealed one by one, at an increasing rhythm. Then the application launches the automatic scrolling of the cards to the end of the pack.
  <br />
  Simultaneously, cut the deck at the separation between the face up and face down cards, then the face up half is spread over the previously dealt cards. Continuing the process, the other half of the deck is taken face down, turned over and ribbon spread.
  <br />
  With the right timing, the animation on the phone and the manipulation of the deck end at the same time, with a visual picture that matches!
  <br />
  <br />
  <b>1st phase alternative handling by Michael Weber</b>
  <br />
  If coding cards feels difficult, here is another way of doing the first phase that is entirely self-working:<br />
  Let them shuffle the cards. Take the cards back and remove 2-6 of Diamonds but do not show them to audience yet.<br />
  Put them in 2, 3, 4, 5, 6 order facing towards yourself then turn them face down. Set aside the bottom card (6D).
  Explain, “This system can operate as a poker calculator. Put in these cards”, giving them the 2, 3, 4, and 5 in order.<br />
  The Algorithm predicts the best 5th card for this hand is the 6D.
  Turn the card over and reveal that The Algorithm was correct: it's the 6D.
</div>
`,
};

export const coding = {
  fr: `
  <div class="item">
    <span>SÉQUENCE DE CODAGE</span>
  </div>
  <div class="item item-text">
    Le codage d'une carte est une procédure assez simple. Avec un peu de pratique en mode Formation, vous serez un expert !
    <br />
    Le principe est que The Algorithm détermine quelle carte doit être révélée en fonction de l'ordre dans lequel le spectateur tape les quatre premières cartes.
    <br />
    <b>Si votre spectateur fait une erreur en tapant des cartes, il suffit d'appuyer sur la carte qui est apparue pour la faire disparaître !</b>
    <br />
    Vous allez donc leur donner les cartes dans l'ordre qui définira les paramètres de la carte à révéler (couleur, valeur, parité). Voici comment cela fonctionne.

    <ol>
      <li>Obtenez secrètement l'identité de la carte que vous devez coder (en jetant un coup d'œil) en rassemblant sur la table les cartes que le spectateur vient de distribuer. Appelons cela la "carte cible".</li>
      <li>Placez cette carte cible de côté sur la table, toujours face vers le bas.</li>
      <li>Disposez les quatre cartes restantes en éventail, face vers le haut, et triez-les en ordre croissant - de gauche à droite (A, 2, 3, 4, 5, 6, 7, 8, 9, 10, J, Q, K). Si vous avez deux ou plusieurs cartes de même valeur, placez-les dans l'ordre Pique,Coeur,Trèfle,Carreau. (P, Co, T, Ca).</li>
      <li>Retournez ce paquet face cachée et éventez les cartes de gauche à droite.
        <img src="/img/instructions/coding_1FR.png" />
      </li>

      <li>Maintenant, imaginez que chaque dos représente une couleur dans l'ordre CHaSeD. Placez face vers le haut sur la table la carte qui représente la couleur de votre carte cible.
        Si la carte cible est de faible valeur (As à 6), demandez à votre spectateur d'entrer la valeur en premier, suivie de la couleur.
        Si la carte cible est de valeur élevée (7 à Roi), demandez à votre spectateur d'entrer la couleur en premier, suivie de la valeur.
        Dans ce cas, demandez à votre spectateur d'entrer la valeur. Pour toutes les phases suivantes, l'ordre des couleurs et des valeurs est absolument libre.
      </li>

      <li>Ensuite, placez face vers le haut sur la table la carte représentant la valeur de la carte cible et demandez à votre spectateur de la saisir.
        <img src="/img/instructions/coding_2.png" />
      </li>

      <li>Il vous reste maintenant deux cartes. Celle de gauche représente les cartes "impaires" et celle de droite les cartes "paires".
        <img src="/img/instructions/coding_3.png" />
        Placez face visible sur la table la carte qui correspond à la parité de votre carte cible afin que votre spectateur puisse la saisir.
      </li>

      <li>Placez la dernière carte face visible sur la table et faites-la également entrer.
        Si votre carte cible est un Roi, la procédure est légèrement différente. Une fois que vous avez trié les cartes en ordre croissant, imaginez que chaque carte de cet éventail face visible représente une couleur dans l'ordre Pique,Coeur,Trèfle,Carreau.
        Ainsi, de gauche à droite, la première carte représenterait les trèfles, la deuxième les cœurs, la troisième les piques et la quatrième les carreaux. Mémorisez la carte qui se trouve à la position de la couleur de votre Roi Cible. Celle-ci devient alors votre carte cible. (S'il y a un autre Roi à cet endroit, mémorisez la carte qui se trouve à la valeur de la couleur de ce second Roi). Commencez la procédure à partir de l'étape 4.
      </li>
    </ol>

    Entraînez-vous en mode Entraînement et vous serez opérationnel en un rien de temps !
  </div>
`,
  en: `
<div class="item">
  <span>CODING SEQUENCE</span>
</div>
<div class="item item-text">
  Coding a card is a rather simple procedure. With a little bit of practice in Training Mode, you will be an expert!
  <br />
  The principle is that The Algorithm determines what card is to be revealed based on the order in which the spectator keys in the first four cards.
  <br />
  <b>If your spectator makes a mistake when keying cards in, just press on the card that appeared to make it disappear!</b>
  <br />
  You are therefore going to give them the cards in the order that will define the parameters of the card to be revealed (suit, value, parity). Here is how it works.

  <ol>
    <li>Secretly get the identity of the card you have to code in (by means of a peek) as you gather the cards the spectator has just dealt on the table. Let's call it the "Target Card".</li>
    <li>Place this Target Card aside on the table, still facing down.</li>
    <li> Fan the remaining four cards face up and sort them in ascending order - from left to right (A, 2, 3, 4, 5, 6, 7, 8, 9, 10, J, Q, K). If you have two or more cards of the same value, position them in CHaSeD order (C, H, S, D). </li>
    <li>Turn this packet face down and fan the cards from left to right.
      <img src="/img/instructions/coding_1EN.png" />
    </li>

    <li>Now, imagine that each back represents a suit in the CHaSeD sequence. Place face up on the table the card that represents your Target Card's suit.
      If the Target Card is low value (A to 6), have your spectator enter the value first, followed by the suit.
      If the Target Card is high value (7 to K), have your spectator enter the suit first, followed by the value.
      This in mind, have your spectator key it in. For all the next phases, suit/value order is absolutely free.
    </li>

    <li>Next, place face up on the table the card representing the value of the Target Card and have your spectator key it in.
      <img src="/img/instructions/coding_2.png" />
    </li>

    <li>You now have two cards remaining. The one on the left will represent 'odd' while the one on the right will, represent even.
      <img src="/img/instructions/coding_3.png" />
      Place face up on the table the card that corresponds to the parity of your Target Card so that your spectator can key it in.
    </li>

    <li>Place the last card face up on the table and have it keyed in also.

      If your Target Card is a King, then the procedure is slightly different. Once you have sorted the cards in ascending order, imagine that each card in this face-up fan represents a suit in your CHaSeD order. So, from left to right, the first card would represent Clubs, the second card would represent Hearts, the third one would be Spades and the fourth one, Diamonds. Memorize the card that sits at your Target King's suit position. This now becomes your Target Card. (If there is another King there, memorize the card that sits at this second King's suit value.) Start the procedure from step 4.
    </li>
  </ol>

  Practice in Training Mode and you'll be up and running in no time!
</div>
`,
};

export const credits = {
  en: `
  <div class="item  item-text">
<p>Credits are very important to us. It's the roots that makes us reach the stars.</p>
<p>This routine has been created by Yves Doumergue & Xavier Decoret in 2010. Originally sold under the name « CardOracle ». Later in 2021, Gregory Wilson added his touch. This version is sold under the name « The Algorithm ». The name was created by Andy Ng.</p>
<p>There are 4 points to credit: </p>
<ol>
<li>
The idea of coding 1 card with 4 other
  In 1950 Mathematician Fitch Chenay published « Telephone Stud »: « Five cards are randomly picked from the deck and given to a mathemagician, who shows four of them (face up) to another mathemagician. This second person sees nothing else, but promptly reveals the identity of the fifth card . »
  The Fitch Cheney version is still in print, in Math Miracles by W. Wallace Lee, Micky Hades International, Box 476, Calgary, Alberta, Canada.
  He had been followed by Magician Max Katz « M.K. TELEPHONE CODE SYSTEM MAX KAT » (The Cardiste, issue n°5, 1958).
  And many others 
</li>

<li>
  The idea of making a routine with this feature in this field was Al Stanger's incredible poker machine. Gaetan Bloom also published a routine.
  
</li>

<li>
  The idea of feinting a full deck being in order with only a few cards at the beginning and a few cards at the end was used by
  Pit Hartling, who has many routines where a spectator shuffles two decks into the same random order.
  A ruse he has performed for 10+ years is the same as our final display (small matching group - rush through middle - same last cards at the end.)
  If you look at the end of his DVD/Video Little Green Man he does a demo but no explanation.
</li>

<li>
The Balducci Force (cut deeper force)
It has been first published in Hugard Magic Monthly vol6, #8 January 1949 p509 by Fred Braue without credits. Then re-published in HMM vol14, #6, November 1956, p 502 and credited to Balducci and (maybe) Ken Krenzel.
</li>
</ol>
<p></p>
<p>If you have a verifiable source that may complete ours, we will be glad to add it. Contact us at: <a href="mailto:contact@thealgorithm.app?subject=Crediting">contact@thealgorithm.app</a></p>
<p></p>
<p>Thanks to Michael Weber for his help.</p>

</div>
`,
  fr: `
<div class="item  item-text">
<p>Citer les sources fait partie de nos valeurs. Ce sont les racines qui nous permettent de nous hisser vers le ciel.</p>
<p>Cette Routine a été créée par Yves Doumergue & Xavier Decoret en 2010, vendue à l'origine sous le nom « CardOracle ». En 2021, Gregory Wilson y a ajouté sa patte. Cette routine se vend sous le nom de « The Algorithm ». C'est Andy Ng qui a eu l'idée de ce nom.</p>
<p>Pour cette routine il y a 4 points sur lesquels on peut donner des sources.</p>

<ol>
<li>
L'idée de coder 1 carte avec 4 autres
En 1950 le Mathématicien Fitch Cheney publie “Telephon stud” : “Cinq cartes sont tirées au hazard d'un jeu et données au mathémagicien, qui en montre quatre, face en l'air, à un autre mathémagicien. Ce dernier ne voit rien d'autre que les quatre cartes, cependant il révèle immédiateemnt l'identité de la cinquième carte.”
La version de Fitch Cheney est toujours disponible dans “Math Miracles” de W. Wallace Lee aux editions Micky Hades International, Box 476, Calgary, Alberta, Canada.
Il a été suivit quelque années plus tard par le magicien Max Katz. « M.K. TELEPHONE CODE SYSTEM MAX KAT » (The Cardiste, issue n°5, 1958).

</li><li>La première idée de faire une routine de ce genre est “Poker Machine” du magicien Al Stanger. Gaetan Bloom a aussi publié une routine avec ce principe.
</li><li>L'idée de donner l'illusion d'un jeu ordonné avec simplement quelques cartes au début et quelque cartes à la fin
Pit Hartling  à son actif plusieurs routines où le spectateur mélange deux jeux dans le même ordre.
Il utilise une ruse similaire à la notre depuis au moins 20 ans.
Vous trouverez une démonstration à la fin de sa video “Little green man”…sans explications.
</li><li> Le forçage Balducci « Cut deeper force »
The Cut Deeper Force de Balducci a tout d'abord été décrit dans Hugar Magic Monthly, vol. 6, no. 8 de janvier 1949, page 509 par Fred Braue sans l'attribuer a qui que ce soit. Puis, toujours dans HMM, dans le vol. 14, no. 6 de novembre 1956, page 502, cette fois attribué à Balducci et (peut-être) Ken Krenzel.
</li>
</ol>
<p>Si vous avez une source vérifiable pour compléter cette page, ce sera avec plaisir que nous l'ajouterons. Envoyez-nous un email à : <a href="mailto:contact@thealgorithm.app?subject=Remerciements">contact@thealgorithm.app</a> </p>
<p></p>
<p>Merci à Michael Weber pour son aide.</p>
</div>
`,
};
