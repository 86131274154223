var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"calculator"},[_c('svg',{attrs:{"height":"100%","width":"100%","viewBox":`40 40 ${_vm.DIFF_X * 4 + 20} ${_vm.DIFF_X * 5 + 20}`,"baseProfile":"full","xmlns":"http://www.w3.org/2000/svg"}},[_c('defs',[_c('linearGradient',{attrs:{"id":"g1","y2":"1"}},[_c('stop',{attrs:{"stop-color":"#FFFFFF"}})],1),_c('linearGradient',{attrs:{"id":"g2","y2":"1"}},[_c('stop',{attrs:{"stop-color":"#000000"}})],1)],1),_vm._l((_vm.grid),function(el,i){return _c('g',{directives:[{name:"touch",rawName:"v-touch:tap",value:(() => _vm.onKey(el)),expression:"() => onKey(el)",arg:"tap"}],key:i + '-' + el.index,staticClass:"key",class:[el.type, el.color, { selected: _vm.selected(el) }]},[(el.pos.w == 2)?_c('rect',{staticClass:"bg",attrs:{"x":_vm.DIFF_X * el.pos.x - 45,"y":_vm.DIFF_X * el.pos.y - 45,"width":_vm.DIFF_X * 2 - 20,"height":90,"rx":"45","ry":"45"}}):_c('circle',{staticClass:"bg",attrs:{"cx":_vm.DIFF_X * el.pos.x,"cy":_vm.DIFF_X * el.pos.y,"r":"45"}}),(el.type == 'suit' && !_vm.selected(el))?_c('image',{attrs:{"href":'/img/suits/Suit - ' + el.suit + '.svg',"x":_vm.DIFF_X * el.pos.x - 50 / 2,"y":_vm.DIFF_X * el.pos.y - (50 / 2) * 0.98,"height":"50","width":"50"}}):_vm._e(),(el.type == 'suit' && _vm.selected(el))?_c('image',{attrs:{"href":'/img/suits/Suit - ' + el.suit + '.svg',"x":_vm.DIFF_X * el.pos.x - 50 / 2,"y":_vm.DIFF_X * el.pos.y - (50 / 2) * 0.98,"height":"50","width":"50"}}):_vm._e(),(el.type != 'suit')?_c('text',{attrs:{"x":_vm.DIFF_X * el.pos.x + (el.pos.w == 2 ? 45 : 0),"y":_vm.DIFF_X * el.pos.y +
          18 -
          (el.pos.w == 2 ? 2 : 0) -
          (el.type == 'special' ? 7 : 0),"font-family":"Verdana","text-anchor":"middle","font-size":el.type == 'special' ? 30 : 50}},[_vm._v(" "+_vm._s(el.type == "special" ? "EXIT" : el.label)+" ")]):_vm._e(),(el.type == 'special')?_c('text',{attrs:{"opacity":"1","x":_vm.DIFF_X * el.pos.x + (el.pos.w == 2 ? 45 : 0),"y":_vm.DIFF_X * el.pos.y +
          18 -
          (el.pos.w == 2 ? 2 : 0) -
          (el.type == 'special' ? -5 : 0),"font-family":"Verdana","text-anchor":"middle","font-size":el.type == 'special' ? 30 : 50}},[_vm._v(" "+_vm._s(el.type == "special" ? _vm.specialLabel : el.label)+" ")]):_vm._e()])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }