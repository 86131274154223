
import CodeComponent from "@/components/CodeComponent.vue";
import InstallGuide from "@/components/InstallGuide.vue";
import VideoBackground from "@/components/VideoBackground.vue";
import game from "@/managers/GameManager";
import userManager from "@/managers/UserManager";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    VideoBackground,
    CodeComponent,
    InstallGuide,
  },
})
export default class Home extends Vue {
  showInstall = false;
  androidShowInstallButton = true;

  public mounted(): void {
    this.checkInstalledOnAndroid();
  }

  async checkInstalledOnAndroid() {
    if ("getInstalledRelatedApps" in navigator) {
      const relatedApps = await (
        navigator as { getInstalledRelatedApps: () => Promise<unknown[]> }
      ).getInstalledRelatedApps();
      this.androidShowInstallButton = relatedApps.length === 0;
    }
  }

  get user() {
    return userManager.currentUser;
  }

  get isInstalledOnAndroid() {
    return !this.androidShowInstallButton;
  }

  get isInstalledOnIOS() {
    if ("standalone" in navigator) {
      return (navigator as { standalone: boolean }).standalone;
    }

    return false;
  }

  get canInstall() {
    if ("standalone" in navigator) {
      return (
        userManager.canInstall &&
        this.premium &&
        !(navigator as { standalone: boolean }).standalone
      );
    }

    return userManager.canInstall && this.premium;
  }
  get logged() {
    return this.user ? true : false;
  }
  get premium() {
    return this.user && this.user.code;
  }
  play() {
    game.end();
    this.$router.push({ name: "game" });
  }
  share() {
    this.$router.push({ name: "share" });
  }
  settings() {
    this.$router.push({ name: "settings" });
  }
  login() {
    this.$router.push({ name: "sign-in" });
  }
  signup() {
    this.$router.push({ name: "sign-up" });
  }

  unlock() {
    if (this.isInstalledOnAndroid || this.isInstalledOnIOS) {
      this.login();
    } else {
      this.signup();
    }
  }

  install() {
    this.showInstall = true;
  }
}
