import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import GameView from "@/views/GameView.vue";
import Home from "@/views/Home.vue";
import auth from "@/managers/AuthManager";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/engine",
    name: "game",
    component: GameView,
    meta: { auth: true },
  },
  {
    path: "/share",
    name: "share",
    component: () =>
      import(/* webpackChunkName: "share" */ "../views/SharePage.vue"),
    meta: { auth: true },
  },
  {
    path: "/settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/SettingsPage.vue"),
    children: [
      {
        path: "/",
        name: "settings",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/settings/SettingsListPage.vue"
          ),
      },
      {
        path: "coding",
        name: "settings.coding",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/settings/CodingPage.vue"
          ),
        meta: { auth: true },
      },
      {
        path: "routine",
        name: "settings.routine",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/settings/RoutinePage.vue"
          ),
        meta: { auth: true },
      },
      {
        path: "language",
        name: "settings.language",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/settings/LanguageSettingsPage.vue"
          ),
      },
      {
        path: "stack",
        name: "settings.stack",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/settings/StackPage.vue"
          ),
        meta: { auth: true },
      },
      {
        path: "layout",
        name: "settings.layout",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/settings/LayoutPage.vue"
          ),
        meta: { auth: true },
      },
      {
        path: "suits-order",
        name: "settings.suits-order",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/settings/SuitOrderPage.vue"
          ),
        meta: { auth: true },
      },
      {
        path: "training",
        name: "settings.training",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/settings/TrainingPage.vue"
          ),
        meta: { auth: true },
      },
      {
        path: "delay",
        name: "settings.delay",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/settings/DelayPage.vue"
          ),
        meta: { auth: true },
      },
      {
        path: "videos",
        name: "settings.videos",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/settings/VideosPage.vue"
          ),
        meta: { auth: true },
      },
      {
        path: "credits",
        name: "settings.credits",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/settings/CreditsPage.vue"
          ),
      },
    ],
  },
  {
    path: "/invite",
    name: "invite",
    component: () =>
      import(/* webpackChunkName: "sign" */ "../views/GuestPage.vue"),
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () =>
      import(/* webpackChunkName: "sign" */ "../views/SignInPage.vue"),
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () =>
      import(/* webpackChunkName: "sign" */ "../views/SignInPage.vue"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () =>
      import(/* webpackChunkName: "sign" */ "../views/SignInPage.vue"),
  },
  {
    path: "/code",
    name: "code",
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "sign" */ "../views/CodePage.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/admin/AdminPage.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const match = to.matched.find((record) => record.meta.auth);
  console.log(match);
  if (match) {
    const user = await auth.getCurrentUser();
    if (!user) {
      next({ name: "home" });
    }
  }
  next();
});
export default router;
