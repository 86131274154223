import Card, { Value } from "@/models/Card";
import GameSettings from "@/models/GameSettings";
import _ from "lodash";
import cardHelper from "./CardHelper";

export const predictAux = (
  settings: GameSettings,
  cards: Card[],
  reversed: boolean
): Card => {
  const u = _.reverse(cardHelper.orderCard(cards, settings.suitsOrder));
  const u2 = cardHelper.orderCard(cards, settings.suitsOrder);

  let suitIndex = _.findIndex(u, cards[0]);
  _.remove(u, (_, i) => i == suitIndex);

  let mod3 = _.findIndex(u, cards[1]);
  _.remove(u, (_, i) => i == mod3);
  mod3 = (mod3 + 1) % 3;

  let mod2 = _.findIndex(u, cards[2]);
  mod2 = (mod2 + 1) % 2;

  let value =
    [1, 2, 3, 4, 5, 6].find((i) => i % 3 == mod3 && i % 2 == mod2) || 0;

  if (reversed) value += 6;

  let suit = settings.suitsOrder[suitIndex];
  const isPresent = () => {
    const cardIndex = _.findIndex(u2, (card) => {
      return value == card.value && suit == card.suit;
    });
    return cardIndex != -1 ? cardIndex : -1;
  };

  let j = isPresent();
  while (j != -1) {
    value = 13;
    suitIndex = j;
    suit = settings.suitsOrder[suitIndex];
    j = isPresent();
  }

  return { suit, value: value as Value };
};

export const predictOtherCard = (
  settings: GameSettings,
  cards: Card[]
): Card => {
  return (
    _.find(settings.groupCards, (card) => !_.find(cards, card)) ||
    settings.groupCards[0]
  );
};

export const predictPreselectedCard = (settings: GameSettings): Card => {
  return settings.finalCard;
};
