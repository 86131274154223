
import { Component, Prop, Vue } from "vue-property-decorator";
import Card from "@/models/Card";
import Theme from "@/assets/themes/cards/card_oracle/ThemeCardsCardOracle";

@Component({
  components: {},
})
export default class CardComponent extends Vue {
  @Prop() card!: Card | "back";

  get src() {
    if (this.card == "back") {
      return Theme.back;
    }
    return Theme.faces[this.card.suit][this.card.value];
  }
}
