import AuthUser from "@/models/user/AuthUser";
import firebaseService from "@/services/FirebaseService";
import Vue from "vue";
import settings from "./SettingsManager";

const THREE_MONTHS = 60 * 60 * 24 * 30 * 3 * 1000;
// const FIVE_SEC = 5 * 1000;

export interface User extends AuthUser {
  code: string | null;
  isAdmin?: boolean;
}
class UserManager {
  private _authUser: AuthUser | null = null;
  private _user: User | null = null;
  public canInstall = true;

  get currentUser() {
    return this._user;
  }

  _userWatcher: () => void = () => {
    // empty
  };
  public setAuthUser(user: AuthUser | null) {
    this._authUser = user;
    settings.restore();

    this._userWatcher();
    if (user) {
      this._userWatcher = this.userRef.onSnapshot((snap) => {
        const data = snap.data() as User;
        if (!data) {
          this.saveUser();
        }
        this._user = snap.data() as User;

        this.saveUserToLocalStorage();
      });
    } else {
      this._user = null;
    }
  }

  private saveUserToLocalStorage() {
    console.log("Saving User data to localStorage");

    localStorage.setItem("userData", JSON.stringify(this._user));
    localStorage.setItem(
      "validUntil",
      JSON.stringify(Date.now() + THREE_MONTHS)
    );
  }

  private removeUserFromLocalStorage() {
    console.log("Removing User data fron localStorage");

    localStorage.removeItem("userData");
    localStorage.removeItem("validUntil");
  }

  private loadUserFromLocalStorage() {
    console.log("Trying to load User data from localStorage");

    const localStorageUserDataString = localStorage.getItem("userData");

    if (localStorageUserDataString) {
      const validUntilString = localStorage.getItem("validUntil");

      if (validUntilString && parseInt(validUntilString) < Date.now()) {
        console.log("User data is outdated.");
        this.removeUserFromLocalStorage();
      } else {
        try {
          console.log("Reading User data from localStorage");

          const parsedUserData = JSON.parse(localStorageUserDataString) as User;

          this._user = parsedUserData;
          this._authUser = parsedUserData;
        } catch (error) {
          console.error("Error while parsing localStorage User data", error);

          this.removeUserFromLocalStorage();
        }
      }
    }
  }

  async saveUser() {
    if (this._authUser) {
      try {
        this.userRef.set(this._authUser, { merge: true });
        this.saveUserToLocalStorage();
      } catch (ex) {
        console.error(ex);
      }
    }
  }

  public async checkCode(codeStr: string) {
    try {
      const activation = firebaseService.functions.httpsCallable("ativateCode");
      await activation({ code: codeStr });
    } catch (ex) {
      throw "invalid code";
    }
  }

  public get userRef() {
    if (!this._authUser) {
      throw "no user";
    }
    return firebaseService.firestore
      .collection("users")
      .doc(this._authUser?.uid);
  }

  initialize() {
    this.loadUserFromLocalStorage();
  }

  private static _singleton: UserManager;
  static get instance(): UserManager {
    if (!UserManager._singleton) {
      UserManager._singleton = Vue.observable(new UserManager());
      UserManager._singleton.initialize();
    }
    return UserManager._singleton;
  }
}

const userManager = UserManager.instance;
export default userManager;
