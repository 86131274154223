
import Card from "@/models/Card";
import { Component, Prop, Vue } from "vue-property-decorator";
import CardComponent from "./CardComponent.vue";
import VideoBackground from "./VideoBackground.vue";
import gsap from "gsap/all";
import settings from "@/managers/SettingsManager";
import cardHelper from "@/helpers/CardHelper";
import _ from "lodash";
import game from "@/managers/GameManager";

@Component({
  components: { VideoBackground, card: CardComponent },
})
export default class CardReveal extends Vue {
  @Prop() card!: Card;
  timeout?: number;
  resumed = false;
  resumable = false;
  get cards() {
    return _.shuffle(
      cardHelper
        .allCards()
        .filter((c) => c.suit != this.card.suit && c.value != this.card.value)
    );
  }
  animate() {
    const delaysByTrickIndex = [2.8, 2, 1.5];
    let tl = gsap.timeline();
    tl.delay(1);
    gsap.set(".card-face", { opacity: 0 });
    gsap.set(".card-global", { opacity: 0 });
    gsap.set(".card-reveal", { perspective: 500 });
    gsap.set(".card-global", { transformStyle: "preserve-3d" });
    // gsap.set(".next-button", {opacity:0})
    // gsap.set([".card-face", ".card-back"], {backfaceVisibility:"hidden"});
    tl.from(this.$el, { opacity: 0, duration: 0.2, ease: "ease.out" });
    tl.fromTo(
      ".loader-holder",
      {
        opacity: 1,
      },
      {
        duration: 0.4,
        opacity: 0,
        ease: "ease.out",
        scale: 0,
        delay: delaysByTrickIndex[game.trickIndex], // phase 4 isn't handled here so it's safe
      }
    );
    tl.fromTo(
      ".card-global",
      {
        yPercent: 30,
        opacity: 0,
      },
      {
        yPercent: 0,
        duration: 1,
        opacity: 1,
        ease: "ease.out",
        // delay:"1"
      }
    );
    // tl.to(".card-global", {opacity: 1 ,duration:0.3},)

    // tl.fromTo(".nopecard",
    //   {
    //     xPercent:()=>random(-100, 100),
    //     yPercent:()=>random(-100, 100),
    //     opacity: 0,
    //    },
    //   {
    //     duration:0.2,
    //     delay:6.5,
    //     xPercent:0,
    //     yPercent:0,
    //     opacity: 1,
    //     ease:"ease.out",
    //     display:"none",
    //     stagger: {
    //       ease: "ease",
    //       // "from":"random",
    //       amount: 3.0,
    //   },
    //     }

    // )

    // tl.set(".nopecard", {opacity:0})
    tl.set(".card-face", { opacity: 1 });

    tl.fromTo(
      ".card-global",
      {
        xPercent: 0,
        rotateY: 180,
      },
      {
        xPercent: 0,
        rotateY: 0,
        duration: 0.8,
        delay: 0.5,
        ease: "ease.inOut",
      }
    );

    tl.fromTo(
      ".card-global",
      {
        scale: 0.8,
      },
      {
        scale: 1,
        duration: 0.5,
        delay: 0.5,
        ease: "ease.out",
      }
    );
    // tl.to(".card-global", {
    //   duration:4,
    //   ease:"ease.in",
    // })

    // tl.to(".square",  {
    //   duration: 1.42,
    //   scale: 0,
    //   translateY: -200,
    //   opacity: 1,
    //   color: "white",
    //   ease: "power2.out",
    //   stagger: {
    //     grid: "auto",
    //     from: "random",
    //     "axis": "y",
    //     ease: "power2",
    //     amount: 5.4
    //   },
    // })

    tl.to(".card-face", { opacity: 1, duration: 0.5 });

    let delay = settings.gameSettings.preditionAutoNextDelay;

    tl.call(() => {
      this.resumable = true;
    });
    if (delay) {
      tl.call(() => {
        this.timeout = setTimeout(() => this.resume(), delay);
      });
    }

    tl.from(".next-button", {
      opacity: 0,
      scale: 0.8,
      duration: 0.6,
      rotate: -45,
      ease: "ease.out",
      delay: 1.5,
    });
  }

  public mounted(): void {
    this.animate();
    // setTimeout(()=>this.resumable = true, 3000)
  }
  public beforeUpdate() {
    this.animate();
  }

  public beforeDestroy(): void {
    // empty
  }

  resume() {
    if (!this.resumable) return;
    if (this.resumed) return;
    this.resumed = true;
    let tl = gsap.timeline();
    gsap.set(".card-back", { opacity: 0 });
    tl.to(".card-global", {
      opacity: 0,
      yPercent: -100,
      duration: 0.5,
      ease: "ease",
    });
    gsap.to(".next-button", {
      opacity: 0,
      yPercent: 100,
      duration: 0.2,
      ease: "ease.in",
    });
    // tl.to(this.$el, {opacity:0, xPercent:100, duration:0.5, ease:"ease.in"})
    tl.call(() => this.$emit("resume"), []);
  }
}
